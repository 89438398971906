import { LoadingOverlay } from '@mantine/core'
import React, { createContext, ReactNode, useContext, useState } from 'react'

import { useInstituteQuery } from '@/features/institute/queries/useInstituteQuery'
import { IInstitute } from '@/features/institute/types'

export interface IInstituteontextState {
  instituteInfo: IInstitute | null
}

const InstituteContext = createContext<IInstituteontextState>({
  instituteInfo: null,
})

const InstituteProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading } = useInstituteQuery('https://glob-hshan.kong.yk8s.me')

  if (isLoading)
    return (
      <LoadingOverlay
        visible={true}
        overlayProps={{ radius: 'sm', blur: 2, backgroundOpacity: 0 }}
        loaderProps={{ color: 'gray', size: 'xl' }}
      />
    )

  return (
    <InstituteContext.Provider value={{ instituteInfo: data ?? null }}>
      {children}
    </InstituteContext.Provider>
  )
}

export default InstituteProvider
export const useInstitute = () => useContext(InstituteContext)

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import _ from 'lodash'

import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

import { IInstitute, IInstituteResponse } from '../types'

export const fetchInstitute = async (serviceUri: string) => {
  const res = await axios.get<IInstituteResponse>(
    `${window.apiEndpoint}/permissions/institutes?service_uri=${serviceUri}`,
    convertCaseList(serviceUri, ConvertType.SNAKE),
  )

  return convertCaseList(res.data, ConvertType.CAMEL) as IInstitute
}

export const useInstituteQuery = (serviceUri: string) => {
  return useQuery({
    queryKey: ['institute', serviceUri],
    queryFn: () => fetchInstitute(serviceUri),
    enabled: !!serviceUri,
  })
}

import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import QueryString from 'qs'

import { getCookie } from './cookies'

export interface IRequestConfig extends AxiosRequestConfig {
  _retry: boolean
}

if (typeof window !== 'undefined') {
  axios.interceptors.request.use(
    function (config) {
      const pass = ['/token', '/oauth/login']
      if (pass.every(black => !config?.url?.includes(black))) return config

      const accessToken = getCookie('access_token')
      if (!accessToken) return Promise.reject('No Token')

      config.headers.authorization = `Bearer ${accessToken}`
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error: AxiosError) {
      const tokenError = new CustomEvent('tokenError', { detail: { error: true } })
      const originalRequest = error.config as IRequestConfig

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        const refreshToken = getCookie('refresh_token')
        if (!refreshToken) return Promise.reject(error)

        const data = QueryString.stringify({
          grantType: 'refresh_token',
          refreshToken,
        })

        try {
          const {
            data: { access_token, refresh_token },
          } = await axios({
            method: 'POST',
            data,
            url: '/api/oauth/token',
          })

          if (originalRequest.headers) {
            originalRequest.headers.authorization = `Bearer ${access_token}`
          } else {
            originalRequest.headers = {
              authorization: `Bearer ${access_token}`,
            }
          }

          return axios(originalRequest)
        } catch (e) {
          const newRefreshToken = localStorage.getItem('refresh_token')
          if (newRefreshToken === refreshToken) {
            window.dispatchEvent(tokenError)
          } else {
            return axios(originalRequest)
          }
          // 추후 사용
          // window.location.replace('/oauth/login')
          return false
        }
      }
      // 추후 사용
      // if (typeof error === 'string' && error === 'No Token') {
      //   window.location.replace('/oauth/login')
      // }
      return Promise.reject(error)
    },
  )
}

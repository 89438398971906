import '@/styles/globals.css'
import 'aos/dist/aos.css'
import '@/shared/utils/axios'
import '@/styles/Main.module.scss'
import '@mantine/core/styles.css'

import { MantineProvider } from '@mantine/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AOS from 'aos'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import { Suspense, useEffect } from 'react'

import InstituteProvider from '@/shared/providers/InstituteProvider'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

export interface IAppProps extends AppProps {
  config: IPublicConfig | null
}

export default function MyApp({ Component, pageProps, config }: IAppProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  const hexToRgba = (hex: string) => {
    let r = parseInt(hex.substring(1, 3), 16)
    let g = parseInt(hex.substring(3, 5), 16)
    let b = parseInt(hex.substring(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, 0.05)`
  }

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    if (!config) return
    if (typeof window.nodeEnv === 'undefined') window.nodeEnv = config.nodeEnv
    if (typeof window.baseUrl === 'undefined') window.baseUrl = config.baseUrl
    if (typeof window.apiEndpoint === 'undefined') window.apiEndpoint = config.apiEndpoint
    if (typeof window.oauthRegisterEndpoint === 'undefined')
      window.oauthRegisterEndpoint = config.oauthRegisterEndpoint
    if (typeof window.oauthLogoutEndpoint === 'undefined')
      window.oauthLogoutEndpoint = config.oauthLogoutEndpoint
    if (typeof window.channelTalkKey === 'undefined') window.channelTalkKey = config.channelTalkKey
    if (typeof window.s3PresignedUrl === 'undefined') window.s3PresignedUrl = config.s3PresignedUrl
    if (typeof window.s3BucketName === 'undefined') window.s3BucketName = config.s3BucketName
    if (typeof window.bebridgeChatbotUrl === 'undefined')
      window.bebridgeChatbotUrl = config.bebridgeChatbotUrl
    if (typeof window.bebridgeChatbotPluginKey === 'undefined')
      window.bebridgeChatbotPluginKey = config.bebridgeChatbotPluginKey
    if (typeof window.bebridgeChatbotBotKey === 'undefined')
      window.bebridgeChatbotBotKey = config.bebridgeChatbotBotKey
    if (typeof window.bebridgeNoteUrl === 'undefined')
      window.bebridgeNoteUrl = config.bebridgeNoteUrl
    if (typeof window.bebridgeNotePluginKey === 'undefined')
      window.bebridgeNotePluginKey = config.bebridgeNotePluginKey
    if (typeof window.cookieDomain === 'undefined') window.cookieDomain = config.cookieDomain

    // 기관 별 색상 적용
    const fetchInstitute = () => {
      // TODO : 환경변수로 받아오도록 수정
      const serviceUri = 'https://glob-hshan.kong.yk8s.me'
      fetch(`${config.apiEndpoint}/permissions/institutes?service_uri=${serviceUri}`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`)
          }
          return response.json()
        })
        .then(data => {
          const instituteInfo = convertCaseList(data, ConvertType.CAMEL)
          document.documentElement.style.setProperty(
            '--primary-color',
            `${instituteInfo.mainColor}`,
          )
          document.documentElement.style.setProperty(
            '--primary-opacity',
            hexToRgba(`${instituteInfo.mainColor}`),
          )
        })
        .catch(error => {
          console.error('Fetch error:', error)
        })
    }

    void fetchInstitute()
  }, [config])

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <MantineProvider>
          <QueryClientProvider client={queryClient}>
            <InstituteProvider>
              <Component {...pageProps} />
            </InstituteProvider>
          </QueryClientProvider>
        </MantineProvider>
      </Suspense>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  let config = null

  if (typeof window === 'undefined') {
    const allConfig: IConfig = require('../shared/utils/config').default
    config = {
      nodeEnv: allConfig.nodeEnv,
      baseUrl: allConfig.baseUrl,
      oauthAuthorizationEndpoint: allConfig.oauthAuthorizationEndpoint,
      oauthClientId: allConfig.oauthClientId,
      oauthScope: allConfig.oauthScope,
      oauthRegisterEndpoint: allConfig.oauthRegisterEndpoint,
      oauthLogoutEndpoint: allConfig.oauthLogoutEndpoint,
      apiEndpoint: allConfig.apiEndpoint,
      oauthRedirectUrl: allConfig.oauthRedirectUrl,
      channelTalkKey: allConfig.channelTalkKey,
      s3PresignedUrl: allConfig.s3PresignedUrl,
      s3BucketName: allConfig.s3BucketName,
      bebridgeChatbotUrl: allConfig.bebridgeChatbotUrl,
      bebridgeChatbotPluginKey: allConfig.bebridgeChatbotPluginKey,
      bebridgeChatbotBotKey: allConfig.bebridgeChatbotBotKey,
      bebridgeNoteUrl: allConfig.bebridgeNoteUrl,
      bebridgeNotePluginKey: allConfig.bebridgeNotePluginKey,
      cookieDomain: allConfig.cookieDomain,
    }
  }

  return { ...appProps, config }
}
